export const isValidPhoneNumber = phone => {
  let phoneNumber = phone.replace(/\s/g, '')
  const regexPatterns = [
    /^20(10|11|12|15)\d{8}$/, // Egypt EG
    /^971(50|52|54|55|56|58|60)\d{7}$/, // United Arab Emirates
    /^966(50|53|54|55|56|57|58|59)\d{7}$/, // Saudi Arabia
    /^974[34567]\d{7}$/, // Qatar
    /^965([2569]\d{7})$/, // Kuwait: mobile numbers (5, 6, 9) or landline numbers (2)
    /^973[367]\d{7}$/, // Bahrain
    /^962(7[0-9]\d{7}|6\d{7})$/, // Jordan: starts with 962 followed by mobile o
    /^9689\d{7}$/, // Oman Mobile: starts with '9689'
    /^9647\d{9}$/ // Iraq (IQ): Mobile numbers start with 9647
  ]

  return regexPatterns.some(regex => regex.test(phoneNumber))
}
